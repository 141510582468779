import { GetStaticProps, NextPage } from 'next';
import { serialize } from 'next-mdx-remote/serialize';

import { content } from '~/content';
import { defaultLocale } from '~/i18n';
import { HomeTemplate } from '~/templates';

export const getStaticProps: GetStaticProps = async () => {
  const fetchWttj = await fetch('https://www.welcomekit.co/api/v1/embed?organization_reference=M6Mk6l');
  const wttj = await fetchWttj.json();

  return {
    props: {
      context: {
        content: {
          eco: await serialize(content.en.eco),
          hero: await serialize(content.en.hero),
          wttj,
        },
        locale: defaultLocale,
      },
    },
  };
};

const HomePage: NextPage = () => <HomeTemplate />;

export default HomePage;
