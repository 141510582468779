import { BreakPoint, minWidth } from '@eshot/ui';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 50px 0;
  text-align: center;

  h1 {
    font-size: 56px;
    font-weight: 500;
    z-index: 1;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #111315;
`;

export const Content = styled.div`
  margin: 50px 0 40px;
  width: 100%;
  height: 300px;

  position: relative;
  overflow: hidden;

  svg {
    position: absolute;
    top: 0;
    left: -40px;

    width: calc(100vw + 80px);

    ${minWidth(BreakPoint.TabletLandscape)} {
      left: 0;
      width: auto;
    }
  }

  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 40px;

  ${minWidth(BreakPoint.TabletLandscape)} {
    margin: 200px 0 40px;
    width: 748px;
    height: 300px;
  }
`;
