import { FC } from 'react';

import {
  SectionHero,
  SectionPartners,
  SectionProducts,
  SectionEngage,
  SectionUseCases,
  SectionCarbonFriendly,
  SectionJoinUs,
  SectionQuotes,
} from '~/components';

export const HomeTemplate: FC = () => {
  return (
    <>
      <SectionHero />
      <SectionProducts />
      <SectionEngage />
      <SectionUseCases />
      <SectionCarbonFriendly />
      <SectionQuotes />
      <SectionJoinUs />
      <SectionPartners />
    </>
  );
};
