import { AnimatedLogo, FacebookIcon, TwitterIcon, LinkedinIcon, MailIcon } from '@eshot/ui';
import { motion } from 'framer-motion';
import { FC } from 'react';

import { containerProps, patternProps, textProps, networkProps } from './TeasingTemplate.motion';
import { Container, Top, Bottom, Content, Launch, LaunchWrap, Networks, Social } from './TeasingTemplate.styles';

export const TeasingTemplate: FC = () => {
  return (
    <Container>
      <Top {...patternProps} />
      <Content>
        <AnimatedLogo />
        <LaunchWrap {...containerProps}>
          <Launch href="https://nft.nyc" target="_blank" rel="noreferrer">
            <motion.span {...textProps}>
              Launch <strong>@NFT.NYC</strong>
            </motion.span>
          </Launch>
        </LaunchWrap>
        <Networks {...networkProps}>
          <Social href="https://www.facebook.com/eShotLabs" target="_blank" rel="noreferrer">
            <FacebookIcon />
          </Social>
          <Social href="https://twitter.com/eShotLabs" target="_blank" rel="noreferrer">
            <TwitterIcon />
          </Social>
          <Social href="https://www.linkedin.com/company/eshot-labs" target="_blank" rel="noreferrer">
            <LinkedinIcon />
          </Social>
          <Social href="mailto:marketing@eshot.io">
            <MailIcon />
          </Social>
        </Networks>
      </Content>
      <Bottom {...patternProps} />
    </Container>
  );
};
