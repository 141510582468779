import { BreakPoint, minWidth } from '@eshot/ui';
import styled from 'styled-components';

import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from '~/components/Header/Header.styles';
import { maxWidthCss } from '~/styles';

export const Container = styled.div`
  margin-top: ${HEADER_HEIGHT_MOBILE + 82}px;
  padding-bottom: 96px;
  ${maxWidthCss};
  ${minWidth(BreakPoint.TabletLandscape)} {
    margin-top: ${HEADER_HEIGHT};
  }

  h1 {
    margin-bottom: 32px;
  }
  h2 {
    margin-bottom: 18px;
    font-size: 24px;
  }

  font-size: 16px;

  table {
    border-spacing: 0;
    th {
      column-gap: 0;
      text-align: left;
      border-bottom: 1px solid ${p => p.theme.palette.neutral.n3};
      padding: 0 8px 12px;
    }
    td {
      border-bottom: 1px solid ${p => p.theme.palette.neutral.n6};
    }
    td {
      padding: 8px;
    }
  }
`;
