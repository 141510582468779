/* eslint-disable import/named */
import { MDXRemote, MDXRemoteProps } from 'next-mdx-remote';
import { FC } from 'react';

import { Container } from './SimpleTemplate.styles';

interface Props {
  content: MDXRemoteProps;
}

export const SimpleTemplate: FC<Props> = ({ content }) => {
  return (
    <Container>
      <MDXRemote {...content} />
    </Container>
  );
};
