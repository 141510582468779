import { MotionProps } from 'framer-motion';

export const containerProps: MotionProps = {
  initial: 'hidden',
  animate: 'visible',
  variants: {
    hidden: {
      y: -20,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        ease: 'easeInOut',
        delay: 2,
        duration: 0.8,
      },
    },
  },
};

export const patternProps: MotionProps = {
  initial: 'hidden',
  animate: 'visible',
  transition: {
    duration: 2,
  },
  variants: {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  },
};

export const textProps: MotionProps = {
  initial: 'hidden',
  animate: 'visible',
  transition: {
    delay: 2.5,
  },
  variants: {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  },
};

export const networkProps: MotionProps = {
  initial: 'hidden',
  animate: 'visible',
  transition: {
    delay: 2.5,
  },
  variants: {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  },
};
