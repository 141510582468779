import { bgImg, BreakPoint, maxWidth } from '@eshot/ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div`
  min-width: 320px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(135deg, #000000, #161616);
  background-color: #000000;
`;

export const Pattern = styled(motion.div)`
  position: absolute;
  width: 100%;
  background-repeat: no-repeat;
  background-position: top center;
`;

export const Top = styled(Pattern)`
  top: 0;
  height: 479px;
  background-size: auto 479px;
  ${bgImg('pattern-top.png', 'pattern-top@2x.png')};
  ${maxWidth(BreakPoint.Desktop)} {
    top: -40px;
  }
`;

export const Bottom = styled(Pattern)`
  bottom: 0;
  height: 247px;
  background-size: auto 247px;
  ${bgImg('pattern-bottom.png', 'pattern-bottom@2x.png')}
  ${maxWidth(BreakPoint.Desktop)} {
    bottom: -20px;
  }
`;

export const Content = styled.div`
  z-index: 10;
  position: relative;
  display: flex;
  gap: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  padding: 32px;
`;

export const Launch = styled(motion.a)`
  display: block;

  font-size: 20px;
  font-style: italic;

  box-shadow: 0px 0px 12px 1px rgba(59, 108, 209, 0.8);
  border-radius: 14px;
  padding: 18px 0;
  background-color: black;

  text-align: center;
  transition: box-shadow .2s ease-in-out;

  width: 335px;
  max-width: 100%;

  &:hover {
    text-decoration: none;
    box-shadow: 0px 0px 30px 1px rgba(59, 108, 209, 0.8);
  }

  span {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const LaunchWrap = styled(motion.div)`
  padding: 1px;
  border-radius: 14px;
  background: linear-gradient(320deg, #6FF1C6, #3B6CD1);
`;

export const Networks = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
`;

export const Social = styled.a`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(130deg, #3B6CD1 13.4%, #6FF1C6 86.6%);
  box-shadow: 0px 0px 10px rgba(80, 135, 241, 0.5);

  display: flex;
  align-items: center;
  justify-content: center;

  transition: box-shadow .2s ease-in-out;

  color: black;

  &:hover {
    box-shadow: 0px 0px 30px rgba(80, 135, 241, 0.5);
  }
`;
